
                    @import "src/styles/_mixins.scss";
                
.container {
    position: relative;
    width: max-content;
    cursor: pointer;

    .wrapper {
        padding: 6px 24px;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        border-radius: 20px;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        svg {
            width: 15px;
        }

        @include mobile {
            font-size: 10px;
        }

        &.error {
            color: var(--error-20);
            background: var(--error-90);

            svg {
                color: var(--error-20);
            }
        }

        &.warning {
            color: var(--primary-10);
            background: var(--primary-80);

            svg {
                color: var(--primary-10);
            }
        }

        &.success {
            color: var(--success-10);
            background: var(--success-90);

            svg {
                color: var(--success-10);
            }
        }
    }


}
