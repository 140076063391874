
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    gap: 24px;

    .content {
        padding: 90px 24px 90px 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;

        &.isBack {
            padding-top: 102px;
        }
    }

    .back_button {
        margin-bottom: 27px;
        padding: 0;
        margin-left: -4px;
        font-size: 16px;
        text-decoration: underline;
    }
}
