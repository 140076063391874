
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  height: 100%;

  .messages_wrapper {
    overflow-y: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    background: var(--primary-100);
    height: 100%;
    padding: 24px 16px;
    background: var(--primary-100);
    border-top: 1px solid var(--secondary-80);
    padding-bottom: 270px;
  }

  .date_divider {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: var(--neutral-50);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 4px 0;
      background: var(--primary-100);
      padding: 0 16px;
      z-index: 10;
    }

    &:before {
      content: ' ';
      width: 100%;
      height: 1px;
      background: var(--neutral-80);
      position: absolute;
      top: 13px;
      left: 0;
    }
  }

  .message_container {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: flex-start;

    .message_wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: calc(100% - 56px);

      .message {
        background: var(--neutral-90);
        border-radius: 8px 8px 8px 2px;
        padding: 16px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 4px;

        .time {
          color: var(--neutral-50);
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }

    &.isSender {
      align-self: flex-end;
      flex-direction: row-reverse;

      .message {
        background: var(--primary-80);
        border-radius: 8px 8px 2px 8px;
      }
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background: var(--primary-80);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: var(--neutral-30);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .input_wrapper {
    width: 100%;
    padding: 16px 32px;
    border-top: 1px solid var(--neutral-70);
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;

    &.withoutInputBorder {
      padding: 0;
    }

    & .withoutInputBorder {
      input {
        border: none;
      }
    }
  }
}