
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;

    &.full {
        width: 100%;
    }

    .datepicker_wrapper_range {
        position: absolute;
        background: white;
        right: 0;
        z-index: 10;
        padding: 24px;
        height: auto;
        box-shadow: 0px 8px 20px 0px rgba(69, 74, 88, 0.15);
        border-radius: 8px;
        margin-top: 4px;
        display: flex;
        gap: 16px;

        @include mobile {
            flex-direction: column;
        }

        .prepaid_dates {
            padding-right: 16px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid var(--neutral-70);

            button {
                padding: 8px 12px;
                border-radius: 4px;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                width: max-content;
                min-width: 132px;

                &:hover {
                    background: var(--primary-90);
                }

                &.checked {
                    background: var(--primary-30);
                    color: white;
                    font-weight: 500;

                    &:hover {
                        background: var(--primary-30);
                    }
                }
            }

            @media (max-width: 820px) {
                border-right: none;
                border-bottom: 1px solid var(--neutral-70);
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                padding-right: 0;
                button {
                    text-align: center;
                }
            }
        }

        .dropdown_range {
            width: 608px;
            table {
                border-spacing: 0;
            }

            @include mobile {
                width: 100%;
            }

            .mantine-DatePicker-levelsGroup {
                display: flex;
                align-self: center;
                gap: 16px;

                @media (max-width: 820px){
                    border-right: none;
                    border-bottom: 1px solid var(--neutral-70);
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                }
            }

            .mantine-DatePicker-levelsGroup > div {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-content: center;
                border-radius: 16px;
                border: 1px solid var(--neutral-70);
                padding: 8px;
            }

            .mantine-DatePicker-day {
                width: 40px;
                height: 40px;
                position: relative;

                &[data-outside="true"], &[data-disabled="true"] {
                    color: var(--neutral-60) !important;
                    background: none !important;
                    &:before {
                        display: none;
                    }
                }
            }

            .mantine-DatePicker-monthCell {
                padding: 0 !important;

                button {
                    border-radius: 100px;
                    font-size: 14px;
                    font-weight: 400;

                    &:hover {
                        background: var(--primary-90);
                        &[data-disabled="true"] {
                            background: none;
                        }
                    }

                    &[data-in-range="true"] {
                        &:before {
                            content: '';
                            z-index: -1;
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            left: 0;
                            background: var(--primary-80);
                        }
                    }

                    &[data-last-in-range="true"] {
                        &:before {
                            content: '';
                            z-index: -1;
                            position: absolute;
                            width: 20px;
                            height: 40px;
                            left: 0;
                            background: var(--primary-80);
                        }
                    }

                    &[data-first-in-range="true"] {
                        &:before {
                            content: '';
                            z-index: -1;
                            position: absolute;
                            width: 20px;
                            height: 40px;
                            right: 0;
                            left: 20px;
                            background: var(--primary-80);
                        }
                    }

                    &[data-today="true"] {
                        color: var(--primary-30);
                        border: 1px solid var(--primary-30);
                        border-radius: 100px;
                    }

                    &[data-selected="true"] {
                        color: white;
                        background: var(--primary-30);
                        border-radius: 100px;
                    }
                }
            }
            .mantine-DatePicker-weekday {
                padding: 0;
                width: 40px;
                height: 40px;
                font-weight: 600;
                font-size: 14px;

            }
            .mantine-DatePicker-calendarHeader {
                width: 100%;
            }
            .mantine-DatePicker-calendarHeaderLevel {
                font-size: 14px;
                font-weight: 600;
                height: 40px;

                &:hover {
                    color: var(--primary-30);
                }
            }
            .mantine-DatePicker-monthsListCell button, .mantine-DatePicker-yearsListCell button {
                width: 100%;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                border-radius: 100px;
                &[data-disable="true"] {
                    background: none;
                }

                &:hover {
                    background: var(--primary-90);
                    &[data-disabled="true"] {
                        background: none;
                    }
                }
            }
            .mantine-DatePicker-calendarHeaderControl {
                width: 24px;
                height: 24px;
                border-radius: 100px;
                margin-top: 8px;
                transition: .3s;

                svg {
                    transition: .3s;
                    width: 100% !important;
                    height: 100% !important;
                }

                &:hover {
                    svg {
                        color: var(--primary-30);
                    }
                }
            }
        }
    }
    }